<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {

	data() {
		return {
			selected: null,
			selectedDetails: null,
			validationIntro: true,
			validationResult: false
		}
	},

	computed: {
		group() {
			if(this.arce) {
				return this.clienById(this.arce.groupId)
			}
			return null
		},

		clients() {
			if(!this.arce) {
				return []
			}

			return this.arce.children
		},

		clientsWithPolicies() {
			return this.clients.filter(c => c.arce.hasOwnProperty('policies'))
		},

		...mapGetters({
			arce: 'arceDetails/arce',
			clienById: 'arceClients/getById',
			userName: 'auth/userName'
		})
	},

	watch: {
		group: {
			handler(nVal) {
				this.selected = nVal.id
			},
			deep: true
		},
		
	},


	methods: {
		getKeyPolicies(policies, id, key) {
			let policy = policies.politicas.find(p => p.id == id)
			return policy[key]
		},
		getTotalPolicies(id, key) {
			let sum = 0
			this.clientsWithPolicies.forEach(c => {
				let policy = c.arce.policies.politicas.find(p => p.id == id) 
				sum += Number(policy[key])
			})

			return sum
		},
		...mapActions({
			getArce: 'arceDetails/getArce',
			_updateArce: 'arceDetails/updateArce'
		}),

	},

	created() {
		this.getArce(this.$route.params.id).then(() => {
			this.selected = this.arce.groupId
			this.selectedDetails = {...this.clienById(this.selected)}
		})
	}
}
</script>
<template>
	<div class="d-flex flex-wrap px-1 py-1 s-single">
		<div class="col-12 p-2">
			<div class="s-panel">
				<table class="s-table w-100 small">
					<thead>
						<tr>
							<td class="px-2 font-weight-bold text-center">Proposed Ammount</td>
							<td class="px-2 font-weight-bold text-center">TOTAL Riesgo Financiero L/P</td>
							<td class="px-2 font-weight-bold text-center">TOTAL Riesgo Financiero L/P Sin Garantías Reales</td>
							<td class="px-2 font-weight-bold text-center">TOTAL Riesgo Financiero L/P Con Garantías Reales</td>
							<td class="px-2 font-weight-bold text-center">TOTAL Riesgo Financiero C/P y Comercial</td>
							<td class="px-2 font-weight-bold text-center">Riesgo Comercial</td>
							<td class="px-2 font-weight-bold text-center">Riesgo Financiero C/P</td>
							<td class="px-2 font-weight-bold text-center">Riesgo De Firma</td>
							<td class="px-2 font-weight-bold text-center">TOTAL RIESGO CORPORATIVO</td>
							<td class="px-2 font-weight-bold text-center">Project Finance</td>
							<td class="px-2 font-weight-bold text-center">TOTAL RIESGO DE CRÉDITO</td>
							<td class="px-2 font-weight-bold text-center">Percentage</td>
						</tr>
					</thead>
					<tbody>
						<tr v-for="c in clientsWithPolicies">
							<td class="py-1">{{c.name}}</td>
							<td class="text-center py-1">${{getKeyPolicies(c.arce.policies, 'total_riesgo_financiero_lp', 'propuesto') | numeral('0,0')}}</td>
							<td class="text-center py-1">${{getKeyPolicies(c.arce.policies, 'riesgo_financiero_lp_sin_garantias_reales', 'propuesto') | numeral('0,0')}}</td>
							<td class="text-center py-1">${{getKeyPolicies(c.arce.policies, 'riesgo_financiero_lp_con_garantias_reales', 'propuesto') | numeral('0,0')}}</td>
							<td class="text-center py-1">${{getKeyPolicies(c.arce.policies, 'total_riesgo_financiero_cp', 'propuesto') | numeral('0,0')}}</td>
							<td class="text-center py-1">${{getKeyPolicies(c.arce.policies, 'riesgo_comercial', 'propuesto') | numeral('0,0')}}</td>
							<td class="text-center py-1">${{getKeyPolicies(c.arce.policies, 'riesgo_financiero_cp', 'propuesto') | numeral('0,0')}}</td>
							<td class="text-center py-1">${{getKeyPolicies(c.arce.policies, 'riesgo_de_firma', 'propuesto') | numeral('0,0')}}</td>
							<td class="text-center py-1">${{getKeyPolicies(c.arce.policies, 'total_riesgo_corporativo', 'propuesto') | numeral('0,0')}}</td>
							<td class="text-center py-1">${{getKeyPolicies(c.arce.policies, 'total_riesgo_corporativo', 'propuesto') | numeral('0,0')}}</td>
							<td class="text-center py-1">${{getKeyPolicies(c.arce.policies, 'total_riesgo_corporativo', 'propuesto') | numeral('0,0')}}</td>
							<td class="text-center py-1">100%</td>
						</tr>
						<tr>
							<td class="py-1">TOTALS</td>
							<td class="text-center py-1">${{getTotalPolicies('total_riesgo_financiero_lp', 'propuesto') | numeral('0,0')}}</td>
							<td class="text-center py-1">${{getTotalPolicies('riesgo_financiero_lp_sin_garantias_reales', 'propuesto') | numeral('0,0')}}</td>
							<td class="text-center py-1">${{getTotalPolicies('riesgo_financiero_lp_con_garantias_reales', 'propuesto') | numeral('0,0')}}</td>
							<td class="text-center py-1">${{getTotalPolicies('total_riesgo_financiero_cp', 'propuesto') | numeral('0,0')}}</td>
							<td class="text-center py-1">${{getTotalPolicies('riesgo_comercial', 'propuesto') | numeral('0,0')}}</td>
							<td class="text-center py-1">${{getTotalPolicies('riesgo_financiero_cp', 'propuesto') | numeral('0,0')}}</td>
							<td class="text-center py-1">${{getTotalPolicies('riesgo_de_firma', 'propuesto') | numeral('0,0')}}</td>
							<td class="text-center py-1">${{getTotalPolicies('total_riesgo_corporativo', 'propuesto') | numeral('0,0')}}</td>
							<td class="text-center py-1">${{getTotalPolicies('total_riesgo_corporativo', 'propuesto') | numeral('0,0')}}</td>
							<td class="text-center py-1">${{getTotalPolicies('total_riesgo_corporativo', 'propuesto') | numeral('0,0')}}</td>
							<td class="text-center py-1">100%</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>